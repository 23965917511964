import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function SensationReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>About Sensation</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Sensation Group founded by Dr.Bhavishya Gupta (MS in Real Estate, NYU, USA and Doctorate in Real Estate Business Management, Sorbonne University, Paris)   with development contracts of over 25 Mn sq ft signed & in p progress in the spheres of Family Entertainment Centres with Multiplexes | Digital Detox & Blackhole Resorts | World Class Plotted Developments | Budget Hotels | Serviced Apartments and State of Art Serviced Hostels for Students, Working Class and Co-living | Blue Collared Housing, across Pan India as the pioneer of adopting Blue Ocean Strategy in the business of Real Estate.</p>

            </p>

            {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">The Operations are run by the Founder and his valuable partners who hail from high level business backgrounds supported by a team of professionals having grass root level approach in Real Estate, having full fledged offices in Dubai, Mumbai, Bengaluru, Chennai, Hyderabad, Goa & Vishakapatnam.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default SensationReadMore;